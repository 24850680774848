<template>
  <transition name="confirm">
    <div
      v-show="value"
      class="comfirm-box"
    >
      <div class="confirm-content">
        <div
          v-if="title"
          class="confirm-content__header"
        >
          <div class="confirm-content__title">
            <span>{{ title }}</span>
            <button
              class="confim-content__headerbtn"
              @click="handleAction('cancel')"
            >
              <i class="iconfont icon-cha"></i>
            </button>
          </div>
        </div>
        <div class="confirm-content__info">
          <div
            v-if="message"
            class="confirm-content__msg"
          >
            <p>{{ message }}</p>
          </div>
          <div v-show="showInput">
            <div class="wd-input">
              <input
                v-model="inputValue"
                type="text"
                class="wd-input__inner"
              >
            </div>
            <div class="confirm-content__errmsg">
              {{ errmsg }}
            </div>
          </div>
        </div>
        <div class="confirm-content__footer">
          <button
            v-show="showCancelButton"
            class="wd-button wd-button--small"
            @click="handleAction('cancel')"
          >
            <span>取消</span>
          </button>
          <button
            v-show="showConfirmButton"
            class="wd-button wd-button--small"
            @click="handleAction('confirm')"
          >
            <span>确认</span>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'Confirm',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    showInput: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    callback: {
      type: Function,
      default: () => {}
    },
    className: {
      type: [String, Array, Object],
      default: ''
    },
    beforeClose: {
      type: Function,
      default: () => {}
    },
    confirmButtonText: {
      type: String,
      default: ''
    },
    cancelButtonText: {
      type: String,
      default: ''
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    showConfirmButton: {
      type: Boolean,
      default: true
    },
    errmsg: {
      type: String,
      default: '错误信息'
    },
    lockOnScroll: {
      type: Boolean,
      default: true
    },
    overlay: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: {
        confirm: false,
        cancel: false
      },
      inputValue: null
    }
  },
  methods: {
    handleAction(action) {
      if (this.showInput === true) {
        this.callback && this.callback(this.inputValue)
      } else {
        this.callback && this.callback(action)
      }
      this.$emit(action)
    },
    close() {
      this.value = false
      if (this.lockOnScroll) {
        setTimeout(() => {
          if (this.overlay && this.bodyOverflow !== 'hidden') {
            document.body.style.overflow = this.bodyOverflow
          }
          this.bodyOverflow = null
        }, 200)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.comfirm-box{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    text-align: center;
    z-index: 2018;
    background: rgba(0, 0,0,0.3);
}
.comfirm-box::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 0;
    vertical-align: middle;
}
.confirm-content{
    width: 420px;
    padding-bottom: 10px;
    vertical-align: middle;
    display: inline-block;
    background: #fff;
    text-align: left;
    .confirm-content__header{
        position: relative;
        padding: 10px;
        padding-bottom: 15px;
    }
    .confirm-content__title{
        padding-left: 0;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 1;
        color: #333;
    }
    .confim-content__headerbtn{
        position: absolute;
        top: 10px;
        right: 15px;
        padding: 0;
        border: 0;
        outline: 0;
        background: transparent;
        font-size: 16px;
        cursor: pointer;
    }
    .confirm-content__status{
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        font-size: 24px!important;
    }
    .confirm-content__status:before{
        content:'&#xe64a;';
        position: absolute;
    }
    .confirm-content__info{
        position: relative;
        padding: 10px 15px;
        color: #606266;
        font-size: 14px;
    }
    .confirm-content__msg{
        margin: 0;
        padding-bottom: 15px;
        & p{
            margin: 0;
            line-height: 24px;
        }
    }
    .confirm-content__errmsg{
        font-size: 12px;
        color:#f56c6c;
        min-height: 18px;
        margin-top: 2px;
    }
    .confirm-content__footer{
        padding: 5px 15px 0;
        text-align: right;
    }
}
.wd-button{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #333;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    font-weight: 500;
    transition: 1s;
}
.wd-button--small{
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
}
.wd-input{
    width: 100%;
}
.wd-input__inner{
    -webkit-appearance: none;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    font-size: inherit;
    background-color: 3#fff;
    background-image:none;
    border-radius: 4px;
    padding: 0 15px;
    outline: none;
    display: inline-block;
    color: #606266;
}
</style>
