
//数组去重

Array.prototype.unique = function(){
 var res = [];
 var json = {};
 for(var i = 0; i < this.length; i++){
  if(!json[this[i]]){
   res.push(this[i]);
   json[this[i]] = 1;
  }
 }
 return res;
}

//限制字符串长度
export function strLimit(str, index){
    if(str.length > index) {
        return str.slice(0,index)+'...'
    }else{
        return str
    }
}

//格式化时间
export function formatDate (date)
{

    if(date == null || date == ''){
        return '';
    }else{
        var d = new Date(date);
        var year = d.getFullYear();
        var month = d.getMonth()+1;
        var day = d.getDate();
        if (month < 10) month = '0' + month;
          if (day < 10) day = '0' + day;

         return [year, month, day].join('-');
        
    }
}
//格式化时间
export function time2Obj(time,symbol='-'){
	time = time?new Date(time):new Date();
	let obj = new Object();
	obj.year = time.getFullYear();
	obj.month = (time.getMonth()+1)>=10?(time.getMonth()+1):'0'+(time.getMonth()+1).toString();
	obj.day = time.getDate()>=10?time.getDate():'0'+time.getDate().toString();
	obj.hour = time.getHours()>=10?time.getHours():'0'+time.getHours().toString();
	obj.minute = time.getMinutes()>=10?time.getMinutes():'0'+time.getMinutes().toString();
	obj.second = time.getSeconds()>=10?time.getSeconds():'0'+time.getSeconds().toString();
    obj.symbolStr = obj.year+symbol+obj.month+symbol+obj.day;
    obj.str_hour = obj.symbolStr +' '+ obj.hour+':00:00';
	obj.str = obj.symbolStr +' '+ obj.hour+':'+obj.minute+':'+obj.second;
	return obj;
}
//切换域名
export function selectLocation(location) {
    let baseUrl ='';
    switch(location) {
        case 'dev-acadmin.anchumall.cn':
        baseUrl = 'https://dev-m.anchumall.cn'
        break;

        case 'fix-acadmin.anchumall.cn':
        baseUrl = 'https://fix-m.anchumall.cn'
        break;

        case 'pre-acadmin.anchumall.cn':
        baseUrl = 'https://pre-m.anchumall.cn'
        break;

        case 'acadmin.hzanchu.com':
        baseUrl = 'https://m.hzanchu.com'
        break;

        default:
        baseUrl = 'https://m.hzanchu.com'

    }
    return baseUrl;
}

//去除空格和中文
export function wordAndSpace(str) {
    let reg = /[\u4e00-\u9fa5]/g;
    str = str.replace(reg, "").replace(/[ ]/g, "")
    console.log(str)
    return str;
}

