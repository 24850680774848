import Vue from 'vue'
import con from './src/confirm.vue'

const ConfirmConstructor = Vue.extend(con)

let instance

const initInstance = () => {
  instance = new ConfirmConstructor({
    el: document.createElement('div')
  })

  instance.$on('input', value => {
    instance.value = value
  })
  instance.$on('confirm', () => {
    instance.value = false
  })
  instance.$on('cancel', () => {
    instance.value = false
  })
  document.body.appendChild(instance.$el)
}

const ConfirmBox = options => {
  return new Promise((resolve, reject) => {
    if (!instance) {
      initInstance()
    }

    Object.assign(instance, {
      resolve,
      reject,
      ...options
    })
  })
}

ConfirmBox.defaultOptions = {
  value: true,
  showInput: false,
  title: '',
  message: '',
  overlay: true,
  lockScroll: true,
  beforeClose: null,
  confirmButtonText: '',
  cancelButtonText: '',
  showConfirmButton: true,
  showCancelButton: false,
  closeOnClickOverlay: false,
  callback: action => {
    instance[action === 'confirm' ? 'resolve' : 'reject'](action)
  }
}
ConfirmBox.alert = (options) => ConfirmBox({
  ...ConfirmBox.currentOptions,
  ...options
})

ConfirmBox.confirm = options => ConfirmBox({
  ...ConfirmBox.currentOptions,
  ...options,
  showCancelButton: true
})

ConfirmBox.prompt = options => ConfirmBox({
  ...ConfirmBox.currentOptions,
  ...options,
  showCancelButton: true,
  showInput: true
})

ConfirmBox.setDefaultOptions = options => {
  Object.assign(ConfirmBox.currentOptions, options)
}

ConfirmBox.resetDefaultOptions = () => {
  ConfirmBox.currentOptions = { ...ConfirmBox.defaultOptions }
}

ConfirmBox.close = () => {
  if (instance) {
    instance.value = false
  }
}

ConfirmBox.install = () => {
  Vue.use(VanConfirmBox)
}

Vue.prototype.$confirmBox = ConfirmBox

ConfirmBox.resetDefaultOptions()

export default ConfirmBox
