var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "confirm" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.value,
            expression: "value",
          },
        ],
        staticClass: "comfirm-box",
      },
      [
        _c("div", { staticClass: "confirm-content" }, [
          _vm.title
            ? _c("div", { staticClass: "confirm-content__header" }, [
                _c("div", { staticClass: "confirm-content__title" }, [
                  _c("span", [_vm._v(_vm._s(_vm.title))]),
                  _c(
                    "button",
                    {
                      staticClass: "confim-content__headerbtn",
                      on: {
                        click: function ($event) {
                          return _vm.handleAction("cancel")
                        },
                      },
                    },
                    [_c("i", { staticClass: "iconfont icon-cha" })]
                  ),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "confirm-content__info" }, [
            _vm.message
              ? _c("div", { staticClass: "confirm-content__msg" }, [
                  _c("p", [_vm._v(_vm._s(_vm.message))]),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showInput,
                    expression: "showInput",
                  },
                ],
              },
              [
                _c("div", { staticClass: "wd-input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inputValue,
                        expression: "inputValue",
                      },
                    ],
                    staticClass: "wd-input__inner",
                    attrs: { type: "text" },
                    domProps: { value: _vm.inputValue },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.inputValue = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "confirm-content__errmsg" }, [
                  _vm._v(" " + _vm._s(_vm.errmsg) + " "),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "confirm-content__footer" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCancelButton,
                    expression: "showCancelButton",
                  },
                ],
                staticClass: "wd-button wd-button--small",
                on: {
                  click: function ($event) {
                    return _vm.handleAction("cancel")
                  },
                },
              },
              [_c("span", [_vm._v("取消")])]
            ),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showConfirmButton,
                    expression: "showConfirmButton",
                  },
                ],
                staticClass: "wd-button wd-button--small",
                on: {
                  click: function ($event) {
                    return _vm.handleAction("confirm")
                  },
                },
              },
              [_c("span", [_vm._v("确认")])]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }